import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import { Link } from "gatsby"

class WideBlogGridCard extends PureComponent {
  static propTypes = {
    imgUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
  }

  render() {
    const { imgUrl, title, subTitle, authorName } = this.props

    const backgroundImgStyle = {
      backgroundImage: `url(${imgUrl})`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingBottom: "60%",
    }

    return (
      <div className="blog-post-card blog-post-card-wide">
        <Link
          to={`/${slugify(title.toLowerCase())}/`}
          className="blog-post-card-thumb-frame"
        >
          <div
            className="blog-post-card-thumb"
            style={backgroundImgStyle}
          ></div>
        </Link>
        <div className="blog-post-card-content">
          <Link
            to={`/${slugify(title.toLowerCase())}/`}
            className="blog-post-card-author"
          >
            written by <span>{authorName}</span>
          </Link>
          <h6>
            <Link to={`/${slugify(title.toLowerCase())}/`}> {title}</Link>
          </h6>
          <p>
            <Link to={`/${slugify(title.toLowerCase())}/`}>{subTitle}</Link>
          </p>
        </div>
      </div>
    )
  }
}

export default WideBlogGridCard
