import React from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import WideBlogGridCard from "../components/global/WideBlogGridCard"

const JobPost = ({ data: { strapiAuthors }, data: { allStrapiArticles } }) => {
  return (
    <Layout bodyTheme="theme-books">
      <SEO
        title={strapiAuthors["firstName"] + " " + strapiAuthors["lastName"]}
      />
      <header>
        <Menu />
      </header>
      <main className="page-wrapper">
        <section className="section section-first">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="author">
                  <div className="author-header">
                    <div>
                      <div className="author-image">
                        <img
                          alt=""
                          data-bg="true"
                          src={
                            strapiAuthors["avatar"]
                              ? strapiAuthors["avatar"]["localFile"][
                                  "publicURL"
                                ]
                              : ""
                          }
                        />
                        <div className="author-details">
                          <div className="name">
                            {strapiAuthors["firstName"] +
                              " " +
                              strapiAuthors["lastName"]}
                          </div>
                        </div>
                      </div>
                    </div>
                    {strapiAuthors["shortBio"] && (
                      <div>
                        <div className="author-short-bio">
                          <h3>Short bio</h3>
                          <p>{strapiAuthors["shortBio"]}</p>
                          <div className="author-question">
                            <h6>
                              {strapiAuthors["sillyQuestion"]
                                ? strapiAuthors["sillyQuestion"]["title"]
                                : ""}
                            </h6>
                            <ReactMarkdown
                              source={
                                strapiAuthors["sillyQuestion"]
                                  ? strapiAuthors["sillyQuestion"]["content"]
                                  : ""
                              }
                            />
                          </div>
                          <ul className="author-contact">
                            <li>
                              <a href={"mailto:" + strapiAuthors["email"]}>
                                {strapiAuthors["email"]}
                              </a>
                            </li>
                            <li>
                              <a
                                href={
                                  "tel:" + strapiAuthors["businessPhoneNumber"]
                                }
                              >
                                {strapiAuthors["businessPhoneNumber"]}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                  {strapiAuthors["bio"] && (
                    <div className="author-bio">
                      <h3>Bio</h3>
                      <ReactMarkdown source={strapiAuthors["bio"]} />
                    </div>
                  )}
                  {allStrapiArticles["nodes"][0] && (
                    <WideBlogGridCard
                      imgUrl={
                        allStrapiArticles["nodes"][0]["thumbnail"]
                          ? allStrapiArticles["nodes"][0]["thumbnail"][
                              "localFile"
                            ]["publicURL"]
                          : ""
                      }
                      title={allStrapiArticles["nodes"][0]["Title"]}
                      subTitle={allStrapiArticles["nodes"][0]["Description"]}
                      authorName={
                        allStrapiArticles["nodes"][0]["author"]
                          ? allStrapiArticles["nodes"][0]["author"]["firstName"]
                          : "" + " " + allStrapiArticles["nodes"][0]["author"]
                          ? allStrapiArticles["nodes"][0]["author"]["lastName"]
                          : ""
                      }
                    />
                  )}
                  {strapiAuthors["razaroon"] && (
                    <div className="razaroon">
                      <div className="razaroon-character">
                        <img
                          alt=""
                          src={
                            strapiAuthors["razaroon"] &&
                            strapiAuthors["razaroon"]["razaroonImage"]
                              ? strapiAuthors["razaroon"]["razaroonImage"][
                                  "localFile"
                                ]["publicURL"]
                              : ""
                          }
                        />
                      </div>
                      <div className="razaroon-content">
                        <div className="razaroon-content-text">
                          <ReactMarkdown
                            source={strapiAuthors["WhyThisRazaroon"]}
                          />
                        </div>
                        <div className="razaroon-title">
                          {strapiAuthors["razaroon"]
                            ? strapiAuthors["razaroon"]["name"]
                            : ""}{" "}
                          -{" "}
                          {strapiAuthors["razaroon"]
                            ? strapiAuthors["razaroon"]["title"]
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="author-favorites">
                    {strapiAuthors["favoriteBook"] && (
                      <div>
                        <div className="favorite-card">
                          <div className="favorite-card-thumb">
                            <img
                              alt=""
                              data-bg="true"
                              src={
                                strapiAuthors["favoriteBook"] &&
                                strapiAuthors["favoriteBook"]["image"]
                                  ? strapiAuthors["favoriteBook"]["image"][
                                      "localFile"
                                    ]["publicURL"]
                                  : ""
                              }
                            />
                          </div>
                          <div className="favorite-card-content">
                            <div className="tag">
                              {strapiAuthors["favoriteBook"]
                                ? strapiAuthors["favoriteBook"]["title"]
                                : ""}
                            </div>
                            <div className="title">
                              {strapiAuthors["favoriteBook"]
                                ? strapiAuthors["favoriteBook"]["name"]
                                : ""}
                            </div>
                            <div className="author">
                              {strapiAuthors["favoriteBook"]
                                ? strapiAuthors["favoriteBook"]["nameSubtitle"]
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {strapiAuthors["favoriteSong"] && (
                      <div>
                        <div className="favorite-card">
                          <div className="favorite-card-thumb">
                            <img
                              alt=""
                              data-bg="true"
                              src={
                                strapiAuthors["favoriteSong"] &&
                                strapiAuthors["favoriteSong"]["image"]
                                  ? strapiAuthors["favoriteSong"]["image"][
                                      "localFile"
                                    ]["publicURL"]
                                  : ""
                              }
                            />
                          </div>
                          <div className="favorite-card-content">
                            <div className="tag">
                              {strapiAuthors["favoriteSong"]
                                ? strapiAuthors["favoriteSong"]["title"]
                                : ""}
                            </div>
                            <div className="title">
                              {strapiAuthors["favoriteSong"]
                                ? strapiAuthors["favoriteSong"]["name"]
                                : ""}
                            </div>
                            <div className="author">
                              {strapiAuthors["favoriteSong"]
                                ? strapiAuthors["favoriteSong"]["nameSubtitle"]
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {(strapiAuthors["githubUrl"] ||
                    strapiAuthors["twitterUrl"] ||
                    strapiAuthors["linkedinUrl"] ||
                    strapiAuthors["email"]) && (
                    <div className="author-share">
                      <ul>
                        {strapiAuthors["githubUrl"] && (
                          <li>
                            <a
                              href={strapiAuthors["githubUrl"]}
                              data-toggle="tooltip"
                              data-title="Visit our GitHub"
                              data-placement="top"
                            >
                              <i className="fab fa-github"></i>
                            </a>
                          </li>
                        )}
                        {strapiAuthors["twitterUrl"] && (
                          <li>
                            <a
                              href={strapiAuthors["twitterUrl"]}
                              data-toggle="tooltip"
                              data-title="Visit our Twitter"
                              data-placement="top"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                        )}
                        {strapiAuthors["linkedinUrl"] && (
                          <li>
                            <a
                              href={strapiAuthors["linkedinUrl"]}
                              data-toggle="tooltip"
                              data-title="Visit our LinkedIn"
                              data-placement="top"
                            >
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                        )}
                        {strapiAuthors["email"] && (
                          <li>
                            <a
                              href={"mailto:" + strapiAuthors["email"]}
                              data-toggle="tooltip"
                              data-title="Send us an email"
                              data-placement="top"
                            >
                              <i className="fal fa-envelope"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $intId: Int!) {
    strapiAuthors(id: { eq: $id }) {
      bio
      businessPhoneNumber
      created_at
      email
      firstName
      githubUrl
      favoriteBook {
        id
        name
        nameSubtitle
        title
        image {
          localFile {
            publicURL
          }
        }
      }
      favoriteSong {
        id
        name
        nameSubtitle
        title
        image {
          localFile {
            publicURL
          }
        }
      }
      linkedinUrl
      lastName
      shortBio
      sillyQuestion {
        content
        title
      }
      twitterUrl
      razaroon {
        name
        title
        razaroonImage {
          localFile {
            publicURL
          }
        }
      }
      avatar {
        localFile {
          publicURL
        }
      }
      WhyThisRazaroon
    }
    allStrapiArticles(
      filter: { author: { id: { eq: $intId } } }
      limit: 1
      sort: { fields: created_at, order: DESC }
    ) {
      nodes {
        Title
        author {
          firstName
          lastName
        }
        Description
        thumbnail {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default JobPost
